<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                 
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  <p>
                      Yangchenphug Higher Secondary School ( Dzongkha : དབྱངས་ཅན་ཕུག་འབྲིང་རིམ་སློབ་གྲྭ་གོང་མ ) is a co-ed public high school in Thimphu, Bhutan. It was founded in 1965 by the third King, His Majesty Jigme Dorji Wangchuck. <br>
                      "Yangchenphug" is a combination of two words: "Yangchen" is short for "Lhamo Yangchenma", the goddess of wisdom and music in Tibetan Buddhism, and "phug" in this context means "hill". The school's annual magazine is named "Yangchen Juedmang", representing the lute, the musical instrument associated with the goddess. Colloquially, the school has come to be known as "Y-Chess." Originally, there cannot be any God or Goddesses in the Buddhist philosophy or teachings. This Goddess, maybe, appears from the ancient Greek philosophy. However, we should always go by the originality and purity of what the Buddha taught us, otherwise Buddhism will turn into any other religion with full of external influences. In a few hundred years we won't know which one Buddha taught us and which one we watch Anime.
                      </p><br>
                      <h3>History of School</h3>
                     <p> Yangchenphug Higher Secondary School has historically been a premier high school in the country, with many notable alumni, including the fifth king, Jigme Khesar Namgyel Wangchuck. The school was started in 1965 by His Majesty Jigme Dorji Wangchuck. Initially the school started as a co-ed, residential school named Thimphu Public School, run on the pattern of Public schools in India. The school had classes from Kindergarten to four. The first faculty included 6 teachers. In 1976, Thimphu Public School was renamed to Yangchenphug Central School; a decade later it became Yangchepnhug High School. In 2001, the institution was given the name it now holds, Yangchenphug Higher Secondary School. This was part of the Education Ministry's drive at the categorization of levels of schools.
<br>
In the 70s, the school had a strength of just over 250. During the early 90s, the student number steadily rose to about 500, primarily consisting of resident students and a few day scholars. In 1994, Yangchengphug became a day school. To cope with admissions pressure and accommodate a rising student body, hostel buildings were transformed into classrooms. Today, the school is the largest school in the country with grades ranging from 9-12 and a total strength of over 1500 students, close to 100 faculty members and staff, and 65-acres of property.
<br>
The school boasts a powerful alumni body. Urban myth holds that almost 1 in 4 civil servant in the country belong to YHSS. For this reason, the school holds a legendary reputation both within and outside the country. Notable Alumni include His Majesty King Jigme Khesar Namgyel Wangchuck the present king of the country, a host of ministers, secretaries, and executives.
                  </p>
                  <br>
                  <h3>Courses Offered</h3>
                  <p>When it first started in 1965 as a Public school, the school offered Dzongkha, English, History, Math, Science, and Geography. Since the re-categorization of schools under the Ministry of Education in 2001, the school offers a wider range of courses. For 9th and 10th graders, it offers Math, Physics, Chemistry, Biology, History, Geography, English and Dzongkha. The students from these two grades also hold the choice of either opting for Economics or Computer Studies as their elective course. At the end of the academic year, the 10th graders sit for a nationwide standardized test Bhutan Certificate for Secondary Education. Depending on their results, students have 3 fields to choose from. Starting 2013, the school offered all these 3 fields: Science, Commerce, and Humanities. Courses in Science are Physics, Chemistry, Pure Mathematics, English and Dzongkha with the elective subjects being Biology or Computer Studies or neither [in which the case the students don't have to choose either]. Courses in Commerce are Business studies, Accountancy, Business mathematics, English and Dzongkha with elective subjects being Economics or Computer Studies or neither. Courses in Humanities are English, Dzongkha, Geography [These subjects are compulsory], electives include, Dzongkha Literature or English Literature, History or Business Mathematics and Economics.</p>
                  <br>
                  <h3>Culture</h3>
                  <p>"It warms my heart to think that my fellow teachers, both past, and present, and scholars, both past, and present, have lived up to the image of an institution that has to be Yangchenphug," wrote the education minister, Thakur S Powdyel in one of the volumes of Yangchen Juedmang. Yangchenphug was the first school to introduce the system of providing school identity cards' to the students as well as teachers. The school was the first to have track-suits and T-shirts with colors specific to Houses the students are grouped into. Although this was initially met with some resistance over the choice of wearing the school uniform, "gho" and "kira", the idea proved to be highly effective, especially during sports heats and other social work. This system of designing school track suits is now replicated in many other schools following the noble initiative born in the pioneer school, Yangchenphug. The school color is Blue. The school celebrates a form of Tshechu every year on 2 June coinciding with the National Social Forestry Day. On the day, students present their performances in Traditional Dance and Mask Dance. The School hosts Religious Talks by various Lamas for the benefit of the Buddhist students. In keeping with the religious traditions of the Education Department, the students have assemblies every morning where they sing Buddhist chants. Some non-Buddhist students have voiced out their dismay at this culture but so far, the matter hasn't been dealt with. Each student is given a Guide book at the start of each term which contain the details of Exam dates, Competition dates, Rules and Regulations of the school and the school Motto and vision statement.</p>
                  <br>
                  <h3>Academics</h3>
                  <p>Yangchenphug holds the record for being among the best schools in the country for producing top results every year during the Standardised BSCE and BHSEC tests. The school focuses a lot on the academics of the students and therefore hold a collection of awards for Academic Toppers. The awards are meant to encourage the students to take more interest in studies. The Academic Facet of the school is looked over directly by the Principal. The current principal is Mr. Yesh B Ghalley.</p>
                  <br>
                  <h3>Other disciplines</h3>
                  <p>Although much attention is given to Academics, the school places heavy resources in building the other facets of wholesome education. The school has a reputation being the best school, sports-wise the Western Region of the country, winning the Inter-school sports competitions almost every year. The number of the trophies have declined over the years but the number is quite large all the same with the school winning close to 20 major trophies in the year of 2013. The sports co-ordinator for the school is Mr.Sigyel Tshewang. Bureaucrats initially had a problem with the new administration's decision to allow its students to attend the school in Track Suit on Saturdays and days on which, the students have PE but other than that, the school boasts of incredible disciplinary records. The discipline rule book is maintained by the Discipline committee and it includes facets like Hair length, Clause against use of Electronics in the school by students, uniform, etc. To make sure that the rules are adhered to but at the same time student rights are protected, the school has a student services committee headed by Vice-Principal Sita Pradhan. The discipline committee is headed by Vice-principal Kamal Hingmang. The school's culture team under the legendary teacher/vice-principal Karma Wangdi have been raking in trophies for years. The culture team have always been remarked to equal the talent levels that of the members of the national Dance academy (Royal Academy of Performing Arts) The school's religious works are taken care and looked after by Chador Wangdi, a teacher who has served the school since 1990.</p>
                  <br>
                  <h3>Student Body</h3>
                  <p>The school has over 1500 students divided over a range of 4 grades from 9 to 12. The students are represented to the school administration by their class prefects who look after their class and report to the board of Principals. However these class prefects serve more as representatives of the teachers/administration to the students than the other way around. The student representation to the school administration is more or less the group of school prefects [32 as of 2013]. They represent the students' voice in decision making processes for the school. This was famously exemplified in 2012 when the school administration decided to not hold the annual school concert for that year. The dismay from the students was represented to the board of Principals and the student leaders worked hard with support from the students to make the concert happen; success did come to the prefects.</p>
                  <br><h3>Student Statistics</h3>
                  <Stats/>

              </div>
              
          <div class="sidebar">
              <NonAcademicQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import Footer from "../../components/page/Footer.vue";
import Stats from "../../components/page/Stats.vue";







export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, Footer, Stats },
    setup(){
        const title= ref('About Us')
        return {title}
    }
  
}
</script>

<style scoped>

</style>